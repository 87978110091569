const DevToggle = ({store, item, description}) => {
  const { [item]:state, [`${item}Toggle`]: action } = store()
  return (
    <div className="developerUiToggle" onClick={action}>
      <label>{description}</label>
      <div className={state ? "circleContainer active": "circleContainer"}>
        <div className={"circle"}>
        </div>
      </div>
    </div>
  );
}

export default DevToggle;
