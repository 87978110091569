import './geometry/linear-extruded-points.js'
import React, {useMemo, useRef} from "react";
import useDataStore from "../../stores/data";
import Entity from "./wrappers/entity";

function Data() {
  const objects = useDataStore((state) => state.processedObjects);
  return (
    <group>
      {
        objects.map((discipline, i) => {
          return Object.values(discipline.graphicalObjects).map((object, oi) => <Entity key={`ZeObjects-${oi}`} data={object}/>)
        })
      }
    </group>
  );
}

export default Data;