import {useState} from "react";
import {ChevronUp, ChevronDown} from "lucide-react";

const Drawer = ({children, title = "title", ico}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="drawer">
      <div className={open ? "drawerHeader active" : "drawerHeader"} onClick={() => setOpen(!open)}>
        <h1>{title}</h1>
        {open ?  <ChevronUp /> : <ChevronDown />}
      </div>
      <div className={open ? "drawerBody active" : "drawerBody"}>
        {children}
      </div>
    </div>
  )
}
export default Drawer