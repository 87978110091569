import { BufferGeometry, Float32BufferAttribute, Vector3 } from "three";
import { extend } from "@react-three/fiber";
import earcut from "earcut";

class CustomShapeGeometry extends BufferGeometry {
  constructor(points, normalVector = [0, 0, 1]) {
    super();

    const normal = new Vector3(...normalVector).normalize();

    // Ensure the shape is closed
    if (
      points.length > 2 &&
      (points[0][0] !== points[points.length - 1][0] ||
        points[0][1] !== points[points.length - 1][1] ||
        points[0][2] !== points[points.length - 1][2])
    ) {
      points.push([...points[0]]); // Close the shape by adding the first point to the end
    }

    // Align points to the given normalVector
    const transformToPlane = (point) => {
      const projected = new Vector3(...point).projectOnPlane(normal);
      return [projected.x, projected.y, projected.z];
    };

    const transformedPoints = points.map(transformToPlane);

    // Prepare vertices and indices
    const vertices = [];
    const flatPoints = transformedPoints.map(([x, y]) => [x, y, 0]); // Flatten to 2D for earcut triangulation

    // Push vertices
    transformedPoints.forEach(([x, y, z]) => {
      vertices.push(x, y, z);
    });

    // Triangulation: Earcut is used for triangulating polygon shapes
    const flatCoords = flatPoints.flatMap(([x, y]) => [x, y]);
    const triangulatedIndices = earcut(flatCoords);

    // Push indices
    const indices = [];
    triangulatedIndices.forEach((index) => {
      indices.push(index);
    });

    // Set attributes for BufferGeometry
    this.setAttribute("position", new Float32BufferAttribute(vertices, 3));
    this.setIndex(indices);

    // Calculate normals for proper shading
    this.computeVertexNormals();
  }
}

extend({ CustomShapeGeometry });