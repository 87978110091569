import {Environment, Grid, OrbitControls, Stats} from "@react-three/drei";
import Data from "../../components/data/data";
import React, {useEffect, useState} from "react";
import useDeveloperStore from "../../stores/developer-options";
import {Euler, Vector3} from "three";
import Arrow from "../data/visual-aid/arrow";
const useCtrlKey = () => {
  const [isCtrlDown, setIsCtrlDown] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Control') {
        setIsCtrlDown(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Control') {
        setIsCtrlDown(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    // Cleanup listeners
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);
  return isCtrlDown;
};

function Config() {
  const isCtrlDown = useCtrlKey();
  const {
    displayOriginArrows,
    disableWorldAxisCorrection
  } = useDeveloperStore()
  const origin = new Vector3()
  const axisCorrectionRotation = new Euler(-Math.PI/2,0,Math.PI)
  const defaultAxisRotation = new Euler()
  return (
    <>
      <Grid
        args={[10, 10]}
        cellSize={2}
        cellThickness={2}
        cellColor="#6f6f6f"
        sectionSize={1}
        sectionThickness={1}
        sectionColor="#8f8f8f"
        fadeDistance={50}
        fadeStrength={1}
        followCamera={true}
        infiniteGrid={true}
        side={2}
      />
      <OrbitControls enabled={!isCtrlDown}/>
      <Environment files="winter-sky.hdr" blur={0.5}/>
      <color attach="background" args={["#1e497d"]}/>
      <group rotation={
        disableWorldAxisCorrection ? defaultAxisRotation : axisCorrectionRotation
      }>
        <Data/>
        {
          displayOriginArrows ? <>
            <Arrow dir={[1, 0, 0]} start={origin} length={1} color={"red"}/>
            <Arrow dir={[0, 1, 0]} start={origin} length={1} color={"lightgreen"}/>
            <Arrow dir={[0, 0, 1]} start={origin} length={1} color={"blue"}/>
          </> : null
        }
      </group>
      <Stats className="threeDimensionalStatusViewer"/>
    </>
  );
}

export default Config;