import React, { useState, useRef } from 'react';
import './import.css';
import useDataStore from "../../stores/data";
import useGlobalsStore from "../../stores/globals";

const Tooltip = ({ text }) => (
  <div className="tooltip">
    <div className="tooltip-content">
      {text}
    </div>
  </div>
);
function pascalToCamel(jsonContent) {
  const toCamelCase = (str) => str.charAt(0).toLowerCase() + str.slice(1);
  if (Array.isArray(jsonContent)) {
    return jsonContent.map(item => pascalToCamel(item));
  } else if (jsonContent !== null && typeof jsonContent === 'object') {
    return Object.entries(jsonContent).reduce((result, [key, value]) => {
      const camelCaseKey = toCamelCase(key);
      result[camelCaseKey] = pascalToCamel(value);
      return result;
    }, {});
  }

  return jsonContent;
}
const FileUpload = () => {
  const [fileList, setFileList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [fileErrors, setFileErrors] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const { setObjects, setProcessedObjects } = useDataStore()
  const { toggleIsDataLoaded } = useGlobalsStore()
  const canVisualize = fileList.length > 0 &&
    fileList.every(({ file }) =>
      uploadProgress[file.name] === 100 && !fileErrors[file.name]
    );

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          console.debug('Raw file content:', event.target.result);
          const jsonContent = JSON.parse(event.target.result);
          console.debug('Parsed JSON:', jsonContent);
          resolve({ isValid: true, data: pascalToCamel(jsonContent), error: null });
        } catch (error) {
          console.error('JSON parsing error:', error);
          let errorMessage = `JSON Parse Error: ${error.message}\n`;
          const match = error.message.match(/position (\d+)/);
          if (match) {
            const position = parseInt(match[1]);
            const context = event.target.result.substring(
              Math.max(0, position - 20),
              Math.min(event.target.result.length, position + 20)
            );
            errorMessage += `Context around error: "...${context}..."`;
          }
          resolve({
            isValid: false,
            data: null,
            error: errorMessage
          });
        }
      };

      reader.onerror = () => {
        reject(new Error(`Failed to read file ${file.name}`));
      };

      setUploadProgress(prev => ({
        ...prev,
        [file.name]: 0
      }));

      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(prev => ({
            ...prev,
            [file.name]: progress
          }));
        }
      };

      reader.readAsText(file);
    });
  };

  const handleFiles = async (files) => {
    const newFiles = Array.from(files).map((file) => ({
      file,
      progress: 0,
      data: null
    }));

    setFileList(prev => [...prev, ...newFiles]);
    setUploadProgress(prev => ({
      ...prev,
      ...Object.fromEntries(newFiles.map(f => [f.file.name, 0]))
    }));

    for (const fileObj of newFiles) {
      try {
        const { isValid, data, error } = await readFileContent(fileObj.file);

        if (!isValid) {
          setFileErrors(prev => ({
            ...prev,
            [fileObj.file.name]: error
          }));
        } else {
          setFileList(prevList => {
            const updatedList = [...prevList];
            const index = updatedList.findIndex(
              (item) => item.file.name === fileObj.file.name
            );
            if (index !== -1) {
              console.log("TRIGGER HERE", data)
              updatedList[index] = { ...updatedList[index], ...data };
            }
            return updatedList;
          });

          setFileErrors(prev => {
            const newErrors = { ...prev };
            delete newErrors[fileObj.file.name];
            return newErrors;
          });
        }
      } catch (error) {
        console.error('File processing error:', error);
        setFileErrors(prev => ({
          ...prev,
          [fileObj.file.name]: `File processing error: ${error.message}`
        }));
      }
    }

    // Reset the file input value after processing
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const removeFile = (fileName) => {
    setFileList(prev => prev.filter(item => item.file.name !== fileName));
    setUploadProgress(prev => {
      const newProgress = { ...prev };
      delete newProgress[fileName];
      return newProgress;
    });
    setFileErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[fileName];
      return newErrors;
    });

    // Reset the file input value after removing a file
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const onDragLeave = () => {
    setIsDragging(false);
  };

  const onDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFiles(e.dataTransfer.files);
  };

  const onFileChange = (e) => {
    handleFiles(e.target.files);
  };

  const onBrowseClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const onVisualizeClick = () => {
    const successfulFiles = fileList.filter(
      ({ file }) => !fileErrors[file.name]
    );
    console.log("How about here?", successfulFiles);
    const result = successfulFiles.map(f => ({
      name: f.file.name,
      data: f.data,
    }))
    console.log('Files ready for visualization:',
      successfulFiles.map(f => ({
        name: f.file.name,
        data: f.data
      }))
    );
    const processedResult = result.map((f) => {
      const extractedData = f.data.map(d=>({
        ...d,
        fileName: f.name
      }))
      return extractedData;
    })
    setProcessedObjects(processedResult.flat());
    setObjects(result)
    toggleIsDataLoaded();
  };

  return (
    <div className="upload-container">
      <div
        className={`upload-area ${isDragging ? 'dragging' : ''}`}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <h4>Drag and drop JSON file(s) here to begin!</h4>
        <p>
          or, <a href="#" onClick={onBrowseClick}>click here to manually browse.</a>
        </p>
      </div>

      <input
        type="file"
        accept="application/json"
        ref={fileInputRef}
        onChange={onFileChange}
        multiple
        className="hidden-input"
      />

      {fileList.length > 0 && (
        <ul className="file-list">
          {fileList.map(({ file, data }, index) => (
            <li key={`${file.name}-${index}`} className="file-item">
              <div className="file-info">
                <span className="file-name">
                  {file.name}
                  {fileErrors[file.name] && (
                    <span className="error-icon">
                      ⚠️
                      <Tooltip text={fileErrors[file.name]} />
                    </span>
                  )}
                  {data && !fileErrors[file.name] && (
                    <span className="success-icon" title="File parsed successfully">
                      ✓
                    </span>
                  )}
                </span>
                <button
                  className="remove-file"
                  onClick={() => removeFile(file.name)}
                  aria-label={`Remove ${file.name}`}
                >
                  ×
                </button>
              </div>
              <div className="progress">
                <div
                  className={`progress-bar ${fileErrors[file.name] ? 'error' : ''} 
                    ${data && !fileErrors[file.name] ? 'success' : ''}`}
                  style={{ width: `${uploadProgress[file.name] || 0}%` }}
                >
                  {fileErrors[file.name]
                    ? 'Error'
                    : `${uploadProgress[file.name] || 0}%`}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      <button
        onClick={onVisualizeClick}
        disabled={!canVisualize}
        className="visualize-button"
      >
        Click here to visualize files
      </button>
    </div>
  );
};

export default FileUpload;