const DevInput = ({store, item, description}) => {
  const { [item]:state, [`${item}Set`]: action } = store()
  const change = (e) => {
    if(e.type === 'change') {
      action(e.target.value)
    }
  }
  return (
    <div className="developerUiInput">
      <label>{description}</label>
      <input type="number" value={state} onChange={(e)=>change(e)} />
    </div>
  );
}

export default DevInput;
