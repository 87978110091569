import React, {useMemo} from "react";
import { Html } from "@react-three/drei";
import useDeveloperStore from "../../../stores/developer-options";
const ContainingVolume = ({data, level, isSelected, displayText}) => {
  const { getEnabledContainingVolumes, displayContainingVolumePrimitiveNumbers } = useDeveloperStore()
  const width = Math.abs(data.corner2[0] - data.corner1[0]);
  const height = Math.abs(data.corner2[1] - data.corner1[1]);
  const depth = Math.abs(data.corner2[2] - data.corner1[2]);
  const position = [
    (data.corner1[0] + data.corner2[0]) / 2,
    (data.corner1[1] + data.corner2[1]) / 2,
    (data.corner1[2] + data.corner2[2]) / 2
  ];
  const levelColor = [
    '#fff',
    '#00fbde',
    '#ecff00'
  ]
  const randomColor = useMemo(() => '#' + Math.floor(Math.random()*16777211).toString(16).padStart(6, '0'), []);
  const enabledLevels = getEnabledContainingVolumes()
  enabledLevels.push(-2)
  return (enabledLevels.includes(level)) && (
    <group>
      <mesh position={position}>
        <boxGeometry args={[width, height, depth]}/>
        <meshPhysicalMaterial
          wireframe={true}
          transparent={1}
          opacity={1}
          roughness={1}
          metalness={0.1}
          color={isSelected ? randomColor : levelColor[level]}
          side={1}
        />
      </mesh>
      {
        ((displayContainingVolumePrimitiveNumbers && level === 2)) && <Html position={[
        data.corner1[0],
        data.corner1[1],
        data.corner1[2],
      ]}>
        <div style={{ color: randomColor, fontSize: '16px' }}>
          {displayText}
        </div>
      </Html>
      }

    </group>
  )
}

export default ContainingVolume