import { extend } from '@react-three/fiber';
import * as THREE from 'three';

class CustomRingGeometry extends THREE.BufferGeometry {
  constructor(
    majorRadius = 1,
    minorRadius = 0.4,
    tubularSegments = 32,
    radialSegments = 16,
    sweepAngle = Math.PI * 2,
    majorMinorRatio = 1
  ) {
    super();

    const positions = new Float32Array((radialSegments + 1) * (tubularSegments + 1) * 3);
    const normals = new Float32Array((radialSegments + 1) * (tubularSegments + 1) * 3);
    const uvs = new Float32Array((radialSegments + 1) * (tubularSegments + 1) * 2);
    const indices = new Uint16Array(radialSegments * tubularSegments * 6);

    let vertexIndex = 0;
    let uvIndex = 0;
    let indexIndex = 0;

    // Create elliptical cross section
    for (let j = 0; j <= radialSegments; j++) {
      const v = j / radialSegments;
      const phi = v * Math.PI * 2;

      for (let i = 0; i <= tubularSegments; i++) {
        const u = i / tubularSegments;
        const theta = u * sweepAngle;

        // Flip the cross-section by negating X and Y coordinates
        const crossSectionX = Math.cos(phi) * minorRadius * majorMinorRatio;
        const crossSectionY = Math.sin(phi) * minorRadius;

        // Position on the sweep path
        const x = (majorRadius - crossSectionX) * Math.cos(theta); // Flip direction
        const y = (majorRadius - crossSectionX) * Math.sin(theta); // Flip direction
        const z = -crossSectionY; // Invert Z for the cross-section flip

        positions[vertexIndex] = x;
        positions[vertexIndex + 1] = y;
        positions[vertexIndex + 2] = z;

        // Calculate normals
        const normal = new THREE.Vector3(
          -Math.cos(phi) * Math.cos(theta), // Flip normal direction
          -Math.cos(phi) * Math.sin(theta), // Flip normal direction
          -Math.sin(phi)                    // Flip normal direction
        ).normalize();

        normals[vertexIndex] = normal.x;
        normals[vertexIndex + 1] = normal.y;
        normals[vertexIndex + 2] = normal.z;

        uvs[uvIndex] = u;
        uvs[uvIndex + 1] = v;

        if (j < radialSegments && i < tubularSegments) {
          const a = j * (tubularSegments + 1) + i;
          const b = j * (tubularSegments + 1) + i + 1;
          const c = (j + 1) * (tubularSegments + 1) + i + 1;
          const d = (j + 1) * (tubularSegments + 1) + i;

          // Reverse the winding order for the indices
          indices[indexIndex] = a;
          indices[indexIndex + 1] = d;
          indices[indexIndex + 2] = b;
          indices[indexIndex + 3] = b;
          indices[indexIndex + 4] = d;
          indices[indexIndex + 5] = c;

          indexIndex += 6;
        }

        vertexIndex += 3;
        uvIndex += 2;
      }
    }

    this.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    this.setAttribute('normal', new THREE.BufferAttribute(normals, 3));
    this.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    this.setIndex(new THREE.BufferAttribute(indices, 1));
  }
}

extend({ CustomRingGeometry });