import { create } from 'zustand'

const useDataStore = create((set) => ({
  objects: [],
  processedObjects: [],
  addObject: obj => set((state) => ({
    objects: {...state.objects, obj},
  })),
  setObjects: objs => set((state) => ({objects: objs})),
  setProcessedObjects: objs => set((state) => ({processedObjects: objs})),
  selectedObject: -1,
  updateObjectAtIndex: (index, object) => set((state) => {
    return {
      objects: state.objects.map((item, i) => i === index ? object : item),
    };
  }),
  selectObject: idx => set((state) => {
    console.log("Selecting Object with Index:", idx)
    return {selectedObject: idx};
  }),
}))

export default useDataStore;