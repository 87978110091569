import React, {useMemo, useRef} from "react";
import {Vector3} from "three";
import {quaternionFromRotationVectors, extractPointList} from "../../../helpers/helpers";
import '../geometry/custom-shape-geometry';
import Outline from "../visual-aid/outline";
import "../geometry/tapered-extrusion";

const getPoints = (data) => {
  const genericError = 'TaperedExtrudedObject could not interpret data to extract points.';
  if(data.primitiveType === "TaperedExtrudedObject") {
    if(data.baseShape1 && data.baseShape2) {
      return {
        firstShapePoints: data.baseShape1Type === 'Linestring' ? extractPointList(data.baseShape1.shapes) : extractPointList([data.baseShape1]),
        secondShapePoints: data.baseShape2Type === 'Linestring' ? extractPointList(data.baseShape2.shapes) : extractPointList([data.baseShape2]),
      }
    }
  }
  if(data.primitiveType === "Cone") {
    const shapes1Points = extractPointList(Object.values(data.baseShape.shapes1))
    const shapes2Points = extractPointList(Object.values(data.baseShape.shapes2)).toReversed()
    if(shapes1Points.length === shapes2Points.length) {
      return {
        firstShapePoints: shapes1Points,
        secondShapePoints: shapes2Points
      }
    }
  }
  console.log('DATA FOR sERROR BELOW:', data)
  console.error(genericError)
  return []
}
const TaperedLinearExtruded = ({ data }) => {
  const shapes = getPoints(data)
  const position = new Vector3(...data.parent.transitionSystem1);
  const rotationSystem = useRef([
    data.parent.rotationSystemX,
    data.parent.rotationSystemY,
    data.parent.rotationSystemZ,
  ]);
  const q = useMemo(() => quaternionFromRotationVectors(...rotationSystem.current), [rotationSystem]);
  return shapes && shapes.firstShapePoints ? (
    <group
      position={position}
    >
      <group
        quaternion={q}
      >
        <mesh>
          <customTaperedExtrusion args={[shapes.firstShapePoints, shapes.secondShapePoints, {
            capStart: true,
            capEnd: true
          }]} />
          <meshPhysicalMaterial
            side={2}
            roughness={1}
            color="#f00"
          />
        </mesh>
        <Outline points={shapes.firstShapePoints}/>
        <Outline points={shapes.secondShapePoints}/>
      </group>
    </group>
  ) : null;
};

export default TaperedLinearExtruded;