import {PivotControls} from "@react-three/drei";
import React from "react";
import Primitive from "./primitive";
import ContainingVolume from "../custom-primitives/containing-volume";
import primitive from "./primitive";
import useDeveloperStore from "../../../stores/developer-options";

const Entity = ({data}) => {
  const {
    selectedObject,
    selectedGroup,
    selectedPrimitive,
    hideNonSelectedObjects,
    hideNonSelectedGroups,
    hideNonSelectedPrimitives
  } = useDeveloperStore()
  return (
    <>
    {
      (hideNonSelectedObjects && selectedObject != data.objectNumber) ? null : (
        <>
          <ContainingVolume data={data.containingVolume} level={0} isSelected={selectedObject == data.objectNumber}/>
          {
            data.groups.map((g, groupIdx) => (hideNonSelectedGroups && (selectedObject != data.objectNumber || selectedGroup != g.groupNumber)) ? null : (
              <group key={`groupIdx${groupIdx}`}>
                <ContainingVolume data={g.containingVolume} level={1} isSelected={selectedObject == data.objectNumber && selectedGroup == g.groupNumber}/>
                {
                  g.primitives.map((primitive, primIdx) => (
                    (hideNonSelectedPrimitives && (selectedPrimitive != primitive.primitiveNumber|| selectedObject != data.objectNumber || selectedGroup != g.groupNumber)) ? null : (
                    <Primitive
                      data={{
                        ...primitive,
                        objectNumber: data.objectNumber,
                        groupNumber: g.groupNumber,
                        absoluteCoordinates: g.absoluteCoordinates,
                        parent: g
                      }}
                      key={`PrimIdx${primIdx}`}
                      isSelected={selectedPrimitive == primitive.primitiveNumber && selectedObject == data.objectNumber && selectedGroup == g.groupNumber} />
                      )
                  ))}
              </group>
            ))
          }
        </>
      )
    }
    </>
  )
}

export default Entity;