import React, {useMemo, useRef} from "react";
import {Vector3} from "three";
import {extractPointList, quaternionFromRotationVectors} from "../../../helpers/helpers";
import "../geometry/custom-shape-geometry";
import Outline from "../visual-aid/outline";

const getPoints = (data) => {
  return data.primitiveType === "LinearExtrudedObject" && data.baseShapeType === 'LineString'
    ? extractPointList(data.baseShape.shapes)
    : data.baseShape.points;
}

const CustomPointsLinearExtrusion = ({ data }) => {
  const points = getPoints(data)//.splice(0,38)
  const extrusionVector = new Vector3(...data.extrusionVector);
  const position = data.absoluteCoordinates
    ? new Vector3(...data.startPoint)
    : new Vector3(...data.parent.transitionSystem1);
  const rotationSystem = useRef([
    data.parent.rotationSystemX,
    data.parent.rotationSystemY,
    data.parent.rotationSystemZ,
  ]);
  const q = useMemo(() => quaternionFromRotationVectors(...rotationSystem.current), [rotationSystem]);
  // Calculate cap positions
  const halfWay = (extrusionVector.length()/2)
  const cap1Pos = extrusionVector.clone().normalize().multiplyScalar(halfWay);
  const cap2Pos = extrusionVector.clone().normalize().multiplyScalar(-halfWay);

  const normal = extrusionVector.clone().normalize()

  return (
    <group
      position={position}
    >
      <group
        quaternion={q}
      >
        <mesh position={cap1Pos}>
          <customShapeGeometry args={[points, new Vector3(...[200 * normal.x ,0,1])]} />
          <meshPhysicalMaterial
            side={2}
            metalness={0}
            roughness={1}
            color="#fff"
          />
        </mesh>
        <mesh>
          <customLinearExtrudedPointShape args={[points, extrusionVector, { caps: false }]} />
          <meshPhysicalMaterial
            side={2}
            roughness={1}
            color="#fff"
            opacity={0.5}
          />
        </mesh>
        <mesh position={cap2Pos}>
          <customShapeGeometry args={[points, new Vector3(...[200 * normal.x,normal.y,1])]} />
          <meshPhysicalMaterial
            side={2}
            metalness={0}
            roughness={1}
            color="#fff"
          />
        </mesh>
        {/*<Outline points={points}/>*/}
      </group>
    </group>
  );
};

export default CustomPointsLinearExtrusion;