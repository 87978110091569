import { Float32BufferAttribute } from 'three';
import { extend } from '@react-three/fiber';
import * as THREE from 'three';

class CustomCakeGeometry extends THREE.BufferGeometry {
  constructor(
    radius = 1,
    height = 1,
    angleStart = 0,
    angleEnd = Math.PI * 2,
    radialSegments = 32,
    heightSegments = 1,
    openEnded = false
  ) {
    super();

    // Clamp the angles between 0 and 2π
    angleStart = Math.max(0, Math.min(angleStart, Math.PI * 2));
    angleEnd = Math.max(0, Math.min(angleEnd, Math.PI * 2));

    const height_half = height / 2;
    const gridRadial = Math.floor(radialSegments);
    const gridHeight = Math.floor(heightSegments);
    const gridRadial1 = gridRadial + 1;
    const gridHeight1 = gridHeight + 1;

    // Calculate angle step
    const angleDiff = angleEnd - angleStart;
    const angleStep = angleDiff / gridRadial;

    // Buffers
    const vertices = [];
    const normals = [];
    const uvs = [];
    const indices = [];

    // Generate vertices for the cylinder wall
    for (let iy = 0; iy < gridHeight1; iy++) {
      const y = iy * height / gridHeight - height_half;

      for (let ix = 0; ix < gridRadial1; ix++) {
        const angle = angleStart + (ix * angleStep);
        const x = radius * Math.cos(angle);
        const z = radius * Math.sin(angle);

        // Vertex position
        vertices.push(x, y, z);

        // Normal
        const normal = new THREE.Vector3(x, 0, z).normalize();
        normals.push(normal.x, normal.y, normal.z);

        // UV
        uvs.push(ix / gridRadial);
        uvs.push(1 - (iy / gridHeight));
      }
    }

    // Generate indices for the cylinder wall
    for (let iy = 0; iy < gridHeight; iy++) {
      for (let ix = 0; ix < gridRadial; ix++) {
        const a = ix + gridRadial1 * iy;
        const b = ix + gridRadial1 * (iy + 1);
        const c = (ix + 1) + gridRadial1 * (iy + 1);
        const d = (ix + 1) + gridRadial1 * iy;

        indices.push(a, b, d);
        indices.push(b, c, d);
      }
    }

    // Add caps if not open-ended
    if (!openEnded) {
      // Helper function to add a cap
      const addCap = (isTop) => {
        const baseIndex = vertices.length / 3;
        const y = isTop ? height_half : -height_half;
        const normalY = isTop ? 1 : -1;

        // Center vertex
        vertices.push(0, y, 0);
        normals.push(0, normalY, 0);
        uvs.push(0.5, 0.5);

        // Edge vertices
        for (let i = 0; i <= gridRadial; i++) {
          const angle = angleStart + (i * angleStep);
          const x = radius * Math.cos(angle);
          const z = radius * Math.sin(angle);

          vertices.push(x, y, z);
          normals.push(0, normalY, 0);

          const u = (Math.cos(angle) * 0.5) + 0.5;
          const v = (Math.sin(angle) * 0.5) + 0.5;
          uvs.push(u, v);

          // Add triangles (except for last vertex)
          if (i < gridRadial) {
            if (isTop) {
              indices.push(
                baseIndex,
                baseIndex + i + 2,
                baseIndex + i + 1
              );
            } else {
              indices.push(
                baseIndex,
                baseIndex + i + 1,
                baseIndex + i + 2
              );
            }
          }
        }
      };

      addCap(false); // Bottom cap
      addCap(true);  // Top cap
    }

    // Build geometry
    this.setIndex(indices);
    this.setAttribute('position', new Float32BufferAttribute(new Float32Array(vertices), 3));
    this.setAttribute('normal', new Float32BufferAttribute(new Float32Array(normals), 3));
    this.setAttribute('uv', new Float32BufferAttribute(new Float32Array(uvs), 2));
  }

  static fromJSON(data) {
    return new CustomCakeGeometry(
      data.radius,
      data.height,
      data.angleStart,
      data.angleEnd,
      data.radialSegments,
      data.heightSegments,
      data.openEnded
    );
  }
}

// Register the custom geometry
extend({ CustomCakeGeometry });