import React from 'react';

const FlameIcon = ({ width = "37.7mm", height = "30.4mm", color = "#fc2e06", className = "" }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 37.697624 30.432865"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <defs>
                <linearGradient id="swatch2">
                    <stop
                        style={{ stopColor: "#000000", stopOpacity: 1 }}
                        offset="0"
                    />
                </linearGradient>
            </defs>
            <path
                style={{ fill: color, fillOpacity: 1, strokeWidth: 0.264583 }}
                d="m -8.2543921e-7,6.8078339 c 0,0 1.32656402543921,-2.181562 2.92673602543921,-2.258678 2.640424,-0.127247 6.044344,9.1937651 6.044344,9.1937651 0,0 3.5791338,8.97938 7.6985858,13.55206 3.54228,3.93201 12.036394,4.77422 16.224292,-1.018 3.302791,-4.56804 4.455998,-9.53763 4.803665,-14.665485 -0.78167,3.875035 -2.927032,12.046845 -9.38464,12.120505 -6.060189,0.0691 -8.728299,-7.88561 -11.026093,-12.604864 C 15.550231,7.5603519 12.237429,-6.0729518e-6 7.3486512,-6.0729518e-6 1.4284772,-6.0729518e-6 -8.254392e-7,6.8078339 -8.2543921e-7,6.8078339 Z"
            />
            <path
                style={{ fill: color, fillOpacity: 1, strokeWidth: 0.26631 }}
                d="m 31.614851,15.233041 a 3.6,3.5999979 0 0 1 -3.6,3.6 3.6,3.5999979 0 0 1 -3.6,-3.6 3.6,3.5999979 0 0 1 3.6,-3.600002 3.6,3.5999979 0 0 1 3.6,3.600002 z"
            />
        </svg>
    );
};

export default FlameIcon;