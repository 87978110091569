import {extend} from "@react-three/fiber";
import {ArrowHelper, Vector3} from "three";

extend({ ArrowHelper });

// Custom hook for creating an arrow helper
const useArrowHelper = (dir = [1, 0, 0], origin = [0, 0, 0], length = 1) => {
  const direction = new Vector3(...dir).normalize();
  const position = new Vector3(...origin);
  return { direction, position, length };
};

const Arrow = ({
                 dir = [1, 0, 0],
                 start = [0, 0, 0],
                 length = 0.5,
                 color = 0xff0000,
                 headLength = 0.1,
                 headWidth = 0.05
               }) => {
  const { direction, position } = useArrowHelper(dir, start, length);

  return (
    <arrowHelper
      args={[direction, position, length, color, headLength, headWidth]}
    />
  );
};
export default Arrow