import { create } from 'zustand';
const cfgs = {
  toggles: [
    { name: "displayOriginArrows", initialState: false },
    { name: "disableWorldAxisCorrection", initialState: false },
    { name: "displayObjectContainingVolumes", initialState: false },
    { name: "displayGroupContainingVolumes", initialState: false },
    { name: "displayPrimitiveContainingVolumes", initialState: false },
    { name: "displayContainingVolumePrimitiveNumbers", initialState: false },
    { name: "hideNonSelectedObjects", initialState: false },
    { name: "hideNonSelectedGroups", initialState: false },
    { name: "hideNonSelectedPrimitives", initialState: false },
    { name: "hideMesh", initialState: false },
  ],
  inputs: [
    { name: "selectedObject", initialState: -1 },
    { name: "selectedGroup", initialState: -1 },
    { name: "selectedPrimitive", initialState: -1 },
  ]
}

const getFormatted = (type, set) => {
  const types = {
    input: 'Set',
    toggle: 'Toggle'
  }
  const togglesFormatted = {};
  cfgs[type+'s'].forEach(({ name, initialState }) => {
    togglesFormatted[name] = initialState;
    togglesFormatted[`${name}${types[type]}`] = (newState) =>
      set((state) => {
        if(type==='toggle') {
          return {[name]:!state[name]}
        }
        return {[name]: newState}
      })
  });

  return togglesFormatted;
};
const useDeveloperStore = create((set) => ({
  ...getFormatted('toggle', set),
  ...getFormatted('input', set),
  getEnabledContainingVolumes: () => {
    const result = []
    const state = useDeveloperStore.getState()
    if(state.displayObjectContainingVolumes) { result.push(0) }
    if(state.displayGroupContainingVolumes) { result.push(1) }
    if(state.displayPrimitiveContainingVolumes) { result.push(2) }
    return result
  }

}));

export default useDeveloperStore;