import React, {useMemo, useRef, useState} from "react";
import { Quaternion, Vector3, Matrix4} from "three";
import '../geometry/ring.js'
import '../geometry/plane.jsx'
import Arrow from "../visual-aid/arrow";
import {
  calculateRotationQuaternion,
  calculateVectorLength,
  quaternionFromRotationVectors
} from "../../../helpers/helpers";



const Elbow = ({data}) => {
  const [devSelect, setDevSelect] = useState(false);
  const center = data.absoluteCoordinates ? new Vector3() : new Vector3(...data.centerPoint)
  const rotationSystem = useRef([
    data.parent.rotationSystemX,
    data.parent.rotationSystemY,
    data.parent.rotationSystemZ,
  ])
  const q = useMemo(()=> quaternionFromRotationVectors(...rotationSystem.current), [rotationSystem]);
  const pos = new Vector3(...data.parent.transitionSystem1)
  if(devSelect) {
    const obj = {
      objectNumber: data.objectNumber,
      groupNumber: data.groupNumber,
      primitiveNumber: data.primitiveNumber
    }
    if(window.Olist === undefined) { window.Olist = []}
    window.Olist = [...(window.Olist.filter(obj=>obj.objectNumber !== obj.objectNumber)), obj]
  }
  const geometryDefaultPole = new Vector3(1,0,0)
  const geometryDefaultNormal = new Vector3(0,0,1)
  const newPole = new Vector3(...data.minorNormalVector)
  const newNormal = new Vector3(...data.normalVector)
  const normalizedPoleVector = new Vector3(...data.minorNormalVector)
  // const geometryPoleAngle = Math.acos(geometryDefaultPole.dot(normalizedPoleVector))
  const meshQuaternion = calculateRotationQuaternion(
    geometryDefaultNormal,
    geometryDefaultPole,
    newNormal,
    newPole
  )

  const MajorRadius = calculateVectorLength(
    center, // [0, 0.9763124999999999, 0]
    new Vector3(...data.majorAxxisVector) // [1, 0.9763124999999999, 0]
  )
  const MinorRadius = Math.sqrt(Math.pow(data.minorPoleDirVector[0],2) + Math.pow(data.minorPoleDirVector[1],2) + Math.pow(data.minorPoleDirVector[2],2) )
  const uiHelperPositions = [0,0,0]
  return (
    <group>
      <group
        position={pos}
      >
        <group
          quaternion={q}
        >
          <group
            position={center}
          >
            <mesh
              quaternion={meshQuaternion}
            >
              <customRingGeometry
                args={[
                  MajorRadius,
                  MinorRadius,
                  32,
                  16,
                  -data.sweepAngle,
                  data.majorMinorRatio,
                ]}
              />
              <meshPhysicalMaterial
                opacity={1}
                roughness={1}
                metalness={0}
                color='#fff'
                side={2}
              />
              {/*<Arrow dir={data.normalVector} start={[0, 0, 0]} length={2} color={"white"}/>*/}
              {/*<Arrow dir={normalizedPoleVector} start={[0, 0, 0]} length={2} color={"yellow"}/>*/}
            </mesh>
          </group>
        </group>
        {/*<Arrow dir={[1, 0, 0]} start={uiHelperPositions} length={1} color={"red"}/>*/}
        {/*<Arrow dir={[0, 1, 0]} start={uiHelperPositions} length={1} color={"lightgreen"}/>*/}
        {/*<Arrow dir={[0, 0, 1]} start={uiHelperPositions} length={1} color={"blue"}/>*/}
      </group>
    </group>
  )
}

export default Elbow