import './landing.scss';
import Viewer from "../viewer/viewer";
import FlameIcon from "../../assets/icon";
import useGlobalsStore from "../../stores/globals";
import Import from "../../components/import/import";
function Landing() {
  const {
    isDataLoaded,
    developerMode
  } = useGlobalsStore();
  return (
    <div className="landing">
      <div className="header">
        <FlameIcon height={60}/>
        <h1>3D Viewer 2.0</h1>
        {developerMode ? <h3 style={{color: "red"}}>Developer Mode Enabled.</h3> : null}
      </div>
      {isDataLoaded ?
        <>
          <Viewer/>
        </> : <Import/>}
    </div>
  );
}

export default Landing;
