import React, {useMemo, useRef, useState} from "react";
import {Matrix4, Quaternion, Vector3} from "three";
import '../geometry/ring.js'
import '../geometry/plane.jsx'
import {quaternionFromRotationVectors, calculateRotationQuaternion} from "../../../helpers/helpers";

const Torus = ({data, level, isSelected, displayText, absoluteCoordinates}) => {
  const [devSelect, setDevSelect] = useState(false);
  const center = new Vector3(...data.centerPoint)
  // Calculate rotation quaternion from normal vector
  const rotationSystem = useRef([
    data.parent.rotationSystemX,
    data.parent.rotationSystemY,
    data.parent.rotationSystemZ,
  ])
  const q = useMemo(()=> quaternionFromRotationVectors(...rotationSystem.current), [rotationSystem]);



  const pos = new Vector3(...data.parent.transitionSystem1)
  if(devSelect) {
    const obj = {
      ObjectNumber: data.ObjectNumber,
      GroupNumber: data.GroupNumber,
      PrimitiveNumber: data.PrimitiveNumber
    }
    if(window.Olist === undefined) { window.Olist = []}
    window.Olist = [...(window.Olist.filter(obj=>obj.ObjectNumber !== obj.ObjectNumber)), obj]
    console.table(obj)
    console.log(data)
  }
  const geometryDefaultPole = new Vector3(1,0,0)
  const geometryDefaultNormal = new Vector3(0,0,1)
  const newPole = new Vector3(...data.minorNormalVector)
  const newNormal = new Vector3(...data.normalVector)
  const meshQuaternion = calculateRotationQuaternion(
    geometryDefaultNormal,
    geometryDefaultPole,
    newNormal,
    newPole
  )
  return (
    <group>
      <group
        position={pos}
      >
        <group
          quaternion={q}
        >
          <group
            position={center}
          >
            <mesh
              quaternion={meshQuaternion}
            >
              <customRingGeometry
                args={[
                  data.majorRadius,
                  data.minorRadius,
                  32,
                  16,
                  data.sweepAngle,
                  data.majorMinorRatio
                ]}
              />
                <meshPhysicalMaterial
                  opacity={1}
                  roughness={1}
                  metalness={0}
                  color='#fff'
                  side={2}
                />
            </mesh>
          </group>
        </group>
      </group>
    </group>
  )
}

export default Torus