import {Canvas} from "@react-three/fiber";
import React, {useRef} from "react";
import './viewer.scss'
import Config from "../../components/config/config";
import DeveloperUI from "../../components/developer-ui/developer-ui";
import useGlobalsStore from "../../stores/globals";
function Viewer() {
  const canvasRef = useRef(null);
  const {developerMode} = useGlobalsStore()
  return (
    <div className="viewer" id="viewer">
      <>
        <Canvas ref={canvasRef} flat linear camera={{position: [-1.828800000000090, 4.288600000000000, 1.332612500000000], fov: 60, near: 0.0001}}>
          <Config/>
        </Canvas>
        {developerMode ? <DeveloperUI/> : null }
      </>
    </div>
  );
}

export default Viewer;

// On the primitive on radial extruded I have the base shape in extraction