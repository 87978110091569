import './developer-ui.scss'
import useDeveloperStore from "../../stores/developer-options";
import Toggle from "./components/toggle";
import Drawer from "./components/drawer";
import Input from "./components/input";
function DeveloperUI() {

  const optionsList = [
    {
      name: "World",
      children: [
        {
          store: useDeveloperStore,
          item: 'displayOriginArrows',
          description: 'Show Scene Orientation Arrows',
          type: 'toggle'
        },
        {
          store: useDeveloperStore,
          item: 'disableWorldAxisCorrection',
          description: 'Disable World Axis Correction',
          type: 'toggle'
        }
      ]
    },
    {
      name: "Containing Volumes",
      children: [
        {
          store: useDeveloperStore,
          item: 'displayObjectContainingVolumes',
          description: 'Display Object Volumes',
          type: 'toggle'
        },
        {
          store: useDeveloperStore,
          item: 'displayGroupContainingVolumes',
          description: 'Display Group Volumes',
          type: 'toggle'
        },
        {
          store: useDeveloperStore,
          item: 'displayPrimitiveContainingVolumes',
          description: 'Display Primitive Volumes',
          type: 'toggle'
        },
        {
          store: useDeveloperStore,
          item: 'displayContainingVolumePrimitiveNumbers',
          description: 'Display Primitive Numbers',
          type: 'toggle'
        }
      ]
    },
    {
      name: 'Object Dissection',
      children: [
        {
          store: useDeveloperStore,
          item: 'selectedObject',
          description: 'Selected Object',
          type: 'input'
        },
        {
          store: useDeveloperStore,
          item: 'selectedGroup',
          description: 'Selected Group',
          type: 'input'
        },
        {
          store: useDeveloperStore,
          item: 'selectedPrimitive',
          description: 'Selected Primitive',
          type: 'input'
        },
        {
          store: useDeveloperStore,
          item: 'hideNonSelectedObjects',
          description: 'Hide Other Objects',
          type: 'toggle'
        },
        {
          store: useDeveloperStore,
          item: 'hideNonSelectedGroups',
          description: 'Hide Other Groups',
          type: 'toggle'
        },
        {
          store: useDeveloperStore,
          item: 'hideNonSelectedPrimitives',
          description: 'Hide Other Primitives',
          type: 'toggle'
        },
        {
          store: useDeveloperStore,
          item: 'hideMesh',
          description: 'Hide Graphics',
          type: 'toggle'
        }
      ]
    }
  ]
  return (
    <div className="developerUiContainer">
      <h1>Dev Tools</h1>
      {
        optionsList.map((drawer, drawrIdx) => {
          return <Drawer title={drawer.name} key={`developerDrawerIdx${drawrIdx}`}>
            {drawer.children.map(((itemOptions, didx) => {
              if(itemOptions.type === 'toggle') {
                return <Toggle {...itemOptions} key={`developerOption${didx}`}/>
              }
              if(itemOptions.type === 'input') {
                return <Input {...itemOptions} key={`developerOption${didx}`}/>
              }
              return null
            }))}
          </Drawer>
        })
      }
    </div>
  );
}

export default DeveloperUI;
