import {Matrix4, Quaternion, Vector3} from "three";


/**
 * Calculate the rotation quaternion that rotates an object from the default orientation
 * to a new orientation defined by normal and polar direction vectors.
 *
 * @param {Vector3} defaultNormal - The default normal vector of the object.
 * @param {Vector3} defaultPolDir - The default polar direction vector of the object.
 * @param {Vector3} newNormal - The target normal vector of the object.
 * @param {Vector3} newPolDir - The target polar direction vector of the object.
 * @returns {Quaternion} - The resulting quaternion for the rotation.
 */
function calculateRotationQuaternion(
  defaultNormal,
  defaultPolDir,
  newNormal,
  newPolDir) {
  // Normalize vectors
  const normalize = (v) => v.clone().normalize();
  defaultNormal = normalize(defaultNormal);
  defaultPolDir = normalize(defaultPolDir);
  newNormal = normalize(newNormal);
  newPolDir = normalize(newPolDir);

  // Compute third orthogonal vectors
  const defaultThird = new Vector3().crossVectors(defaultNormal, defaultPolDir).normalize();
  const newThird = new Vector3().crossVectors(newNormal, newPolDir).normalize();

  // Construct rotation matrices
  const defaultMatrix = new Matrix4().makeBasis(defaultNormal, defaultPolDir, defaultThird);
  const newMatrix = new Matrix4().makeBasis(newNormal, newPolDir, newThird);

  // Compute the rotation matrix
  const rotationMatrix = new Matrix4().multiplyMatrices(newMatrix, defaultMatrix.clone().invert());

  // Convert the rotation matrix to a quaternion
  const quaternion = new Quaternion().setFromRotationMatrix(rotationMatrix);
  return quaternion;
}

/**
 * Calculate the length of a vector from centerPoint to majorAxisPoint.
 * @param {Vector3} centerPoint - The center point.
 * @param {Vector3} majorAxisPoint - The major axis point.
 * @returns {number} - The length of the vector.
 * */
function calculateVectorLength(centerPoint, majorAxisPoint) {
  // const vector = new Vector3().subVectors(majorAxisPoint, centerPoint);
  // return vector.length();
  return majorAxisPoint.distanceTo(centerPoint)
}

/**
 * Extract Quaternion from Rotation Matrix System used in Vue Files
 * @param {Array<Number>} rotationSystemX - X Rotation
 * @param {Array<Number>} rotationSystemY - Y Rotation
 * @param {Array<Number>} rotationSystemZ - Z Rotation
 * @return {Quaternion} containing the center point
 *
 */
function quaternionFromRotationVectors(
  rotationSystemX,
  rotationSystemY,
  rotationSystemZ
) {
  const rotationVectorX = new Vector3(...rotationSystemX)
  const rotationVectorY = new Vector3(...rotationSystemY)
  const rotationVectorZ = new Vector3(...rotationSystemZ)
  const matrix = new Matrix4(
    rotationVectorX.x, rotationVectorY.x, rotationVectorZ.x, 0,
    rotationVectorX.y, rotationVectorY.y, rotationVectorZ.y, 0,
    rotationVectorX.z, rotationVectorY.z, rotationVectorZ.z, 0,
    0,0,0,1
  )
  return new Quaternion().setFromRotationMatrix(matrix)
}

/**
 * Calculates center point from 0 0 0 to 1 1 1 for example, 0.5 0.5 0.5
 * @param {Vector3} position
 * @param {Vector3} poleDirection
 * @param {Number} length
 * @return {Vector3} containing the center point
 */
function calculateCenterPointWithDirection(position, poleDirection, length) {
  // Make sure we're working with normalized direction
  const normalizedDirection = poleDirection.clone().normalize();
  // Calculate the offset vector
  // Multiply normalized direction by half the length to get to center
  const offset = normalizedDirection.multiplyScalar(length/2);
  // Add the offset to the initial position to get the center point
  return position.clone().add(offset);
}

/** Correct Primitives to desired Angles.
 * @param {Vector3} defaultPole ex: 1, 0, 0
 * @param {Vector3} defaultNormal ex: 0, 1, 0
 * @param {Vector3} newPole 0 1 0
 * @param {Vector3} newNormal 1 0 0
 * @return {Quaternion} primitive correction quaternion
 **/
function correctNormalsAndPoleDirection(
  defaultPole,
  defaultNormal,
  newPole,
  newNormal
) {
  return calculateRotationQuaternion(
    defaultNormal,
    defaultPole,
    newNormal,
    newPole
  )
}

function calculateNewPointWithLengthVector(startingPoint, lengthVector) {
  // Step 1: Calculate the length from the length vector
  const length = lengthVector.length();

  // Step 2: Normalize the normal vector
  const direction = lengthVector.clone().normalize();

  // Step 3: Scale the direction vector by the length
  const scaledDirection = direction.multiplyScalar(length);

  // Step 4: Add the scaled direction to the starting point
  return startingPoint.clone().add(scaledDirection);
}
function getArcPoints(
  startPoint,
  radius,
  startAngle,
  endAngle,
  normalVector,
  poleDirVector,
  segments = 36
) {
  // Compute the center of the arc
  const center = startPoint.clone();

  // Normalize the input vectors
  const poleDirNorm = poleDirVector.clone().normalize();
  const normalNorm = normalVector.clone().normalize();

  // Compute the vector along the radius direction
  const R = poleDirNorm.clone().multiplyScalar(radius);

  // Compute a perpendicular vector Z in the plane of the arc
  let Z = normalNorm.clone().cross(poleDirNorm);
  const zLen = Z.length();
  if (zLen === 0) {
    throw new Error("PoleDirVector and NormalVector are collinear!");
  }
  Z.normalize().multiplyScalar(radius);

  // Generate arc points
  const points = [];
  for (let i = 0; i <= segments; i++) {
    const t = startAngle + (i / segments) * (endAngle - startAngle);
    const cosT = Math.cos(t);
    const sinT = Math.sin(t);

    // Calculate the point on the arc
    const point = center
      .clone()
      .add(R.clone().multiplyScalar(cosT))
      .add(Z.clone().multiplyScalar(sinT));

    points.push(point.toArray());
  }
  return points;
}

function getLinePoints(startPoint, length, poleDirVector) {
  const dir = poleDirVector.clone().normalize();
  const endPoint = startPoint.clone().add(dir.multiplyScalar(length));
  return [startPoint.toArray(), endPoint.toArray()];
}
function comparePoints(p1,p2) {
  return p1[0] === p2[0] && p1[1] === p2[1] && p1[2] === p2[2];
}
function getNurbsCurvePoints(controlPoints, degree, knotVector, segments = 36) {
  // Helper function to evaluate basis functions
  function basisFunction(i, k, t, knotVector) {
    if (k === 0) {
      return (t >= knotVector[i] && t < knotVector[i + 1]) ? 1.0 : 0.0;
    }

    const denom1 = knotVector[i + k] - knotVector[i];
    const denom2 = knotVector[i + k + 1] - knotVector[i + 1];

    const term1 = denom1 === 0 ? 0 : ((t - knotVector[i]) / denom1) * basisFunction(i, k - 1, t, knotVector);
    const term2 = denom2 === 0 ? 0 : ((knotVector[i + k + 1] - t) / denom2) * basisFunction(i + 1, k - 1, t, knotVector);

    return term1 + term2;
  }

  // Generate points along the curve
  const curvePoints = [];
  const n = controlPoints.length - 1;
  const uMin = knotVector[degree];
  const uMax = knotVector[knotVector.length - degree - 1];

  // Create parameter values, excluding the endpoint
  const parameterValues = [];
  for (let s = 0; s < segments; s++) {
    parameterValues.push(uMin + (s / segments) * (uMax - uMin));
  }

  for (const t of parameterValues) {
    let point = [0, 0, 0];

    for (let i = 0; i <= n; i++) {
      const N = basisFunction(i, degree, t, knotVector);
      point[0] += N * controlPoints[i][0];
      point[1] += N * controlPoints[i][1];
      point[2] += N * controlPoints[i][2];
    }

    curvePoints.push(point);
  }
  curvePoints.push(curvePoints[0]);
  return curvePoints;
}
function getConeShapePoints(coneData, segments = 36) {
  const { baseShape } = coneData;
  const { shapes1, shapes2 } = baseShape;

  if (!shapes1 || !shapes2 || shapes1.length === 0 || shapes2.length === 0) {
    throw new Error("Invalid cone shape data: Missing Shapes1 or Shapes2");
  }

  // Helper function to generate a closed circle
  function getClosedCirclePoints(shape, segments) {
    const {
      radius,
      startAngle,
      sweepAngle,
      normalVector,
      poleDirVector,
      startPoint,
    } = shape;

    // Ensure the circle is closed
    const circlePoints = getArcPoints(
      new Vector3(...startPoint),
      radius,
      startAngle,
      sweepAngle,
      new Vector3(...normalVector),
      new Vector3(...poleDirVector),
      segments
    );

    // Add the first point as the last point for closure
    const firstPoint = circlePoints[0];
    const lastPoint = circlePoints[circlePoints.length - 1];
    if (
      firstPoint[0] !== lastPoint[0] ||
      firstPoint[1] !== lastPoint[1] ||
      firstPoint[2] !== lastPoint[2]
    ) {
      circlePoints.push(firstPoint);
    }

    return circlePoints;
  }

  // Compute points for both circles
  const circle1 = getClosedCirclePoints(shapes1[0], segments);
  const circle2 = getClosedCirclePoints(shapes2[0], segments);

  // Connect the two circles to form a cone
  const conePoints = [];
  for (let i = 0; i < segments; i++) {
    conePoints.push([circle1[i], circle2[i]]); // Connect corresponding points
  }

  // Ensure closure between the last and first points
  conePoints.push([circle1[segments], circle2[segments]]);

  return {
    circle1,
    circle2,
    conePoints,
  };
}

function extractPointList(shapes) {
  let pointList = [];
  if (shapes) {
    shapes.forEach((shape, index) => {
      const startPoint = new Vector3(...shape.startPoint);

      const shapeType = shape.shape2DType;
      if (shapeType === "Line") {
        const length = shape.length;
        const poleDirVector = new Vector3(...shape.poleDirVector);

        const linePoints = getLinePoints(startPoint, length, poleDirVector);
        if (index > 0) {
          const firstPointInLinePoints = linePoints[0];
          const lastPointInPointList = pointList[pointList.length - 1];
          if (comparePoints(firstPointInLinePoints, lastPointInPointList)) {
            linePoints.shift();
          }

        }
        pointList.push(...linePoints);
      } else if (shapeType === "Circle") {
        const radius = shape.radius;
        const startAngle = shape.startAngle;
        const sweepAngle = shape.sweepAngle;
        const normalVector = new Vector3(...shape.normalVector);

        const poleDirVector = new Vector3(...shape.poleDirVector);
        let arcPoints = getArcPoints(startPoint, radius, startAngle, sweepAngle, normalVector, poleDirVector);
        if (index > 0) {
          const firstPointInArcPoints = arcPoints[0];
          const lastPointInPointList = pointList[pointList.length - 1];
          if (comparePoints(firstPointInArcPoints, lastPointInPointList)) {
            arcPoints.shift();
          }
        }
        pointList.push(...arcPoints);
      } else if (shapeType === "NurbsCurve") {
        const degree = shape.degree;
        const controlPoints = shape.controlPoints;

        const knotVector = shape.knotVector;
        let nurbsPoints = getNurbsCurvePoints(controlPoints, degree, knotVector);
        if (index > 0) {
          const firstPointInNurbsPoints = nurbsPoints[0];
          const lastPointInPointList = pointList[pointList.length - 1];
          if (comparePoints(firstPointInNurbsPoints, lastPointInPointList)) {
            nurbsPoints.shift();
          }
        }
        pointList.push(...nurbsPoints);
      }
    });
  }
  return pointList;
}

export  {calculateRotationQuaternion, calculateVectorLength, quaternionFromRotationVectors, calculateCenterPointWithDirection, correctNormalsAndPoleDirection, calculateNewPointWithLengthVector, extractPointList, getConeShapePoints}