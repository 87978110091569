import React from "react";
import ContainingVolume from "../custom-primitives/containing-volume";
import Cylinder from "../primitives/cylinder";
import useDeveloperStore from "../../../stores/developer-options";
import Elbow from "../primitives/elbow";
import Torus from "../primitives/torus";
import PointsLinearExtrusion from "../primitives/points-linear-extrusion";
import TaperedLinearExtruded from "../primitives/tapered-linear-extrusion";

const Primitive = ({data, isSelected}) => {
  const {hideMesh} = useDeveloperStore()
  return (
    <group>
      {
        <ContainingVolume
          data={data.containingVolume}
          level={2}
          isSelected={isSelected}
          displayText={data.primitiveNumber}/>
      }
      {
        (!hideMesh && ["Cylinder"].includes(data.primitiveType)) ? (
          <Cylinder data={data} isSelected={isSelected}/>
        ) : null
      }
      {
        (!hideMesh && ["RadialExtrudedObject"].includes(data.primitiveType)) ? (
          <Elbow data={data} isSelected={isSelected}/>
        ) : null
      },
      {
        (!hideMesh && ["Torus"].includes(data.primitiveType)) ? (
          <Torus data={data} isSelected={isSelected}/>
        ) : null
      },
      {
        (!hideMesh && ["Box", "LinearExtrudedObject"].includes(data.primitiveType) ? (
          <>
            <PointsLinearExtrusion data={data} isSelected={isSelected}/>
          </>
        ) : null)
      }
      {
        (!hideMesh && ["TaperedExtrudedObject", "Cone"].includes(data.primitiveType) ? (
          <>
            <TaperedLinearExtruded data={data} isSelected={isSelected}/>
          </>
        ) : null)
      }
    </group>
  )
}

export default Primitive;