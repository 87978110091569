import React, {useMemo, useRef} from "react";
import '../geometry/cake'
import {Quaternion, Vector3} from "three";
import {
  quaternionFromRotationVectors,
  calculateCenterPointWithDirection,
  correctNormalsAndPoleDirection
} from "../../../helpers/helpers";


const Cylinder = ({data}) => {
  let position = data.absoluteCoordinates ? new Vector3(...data.startPoint): new Vector3(...data.parent.transitionSystem1)
  const startPoint = data.startPoint ? new Vector3(...data.startPoint) : new Vector3(0,0,0)
  const startAngle = data.baseShapeType === "Circle" ? data.baseShape.startAngle : 0
  const endAngle = data.baseShapeType === "Circle" ? data.baseShape.sweepAngle : 0
  const poleDirection = data.baseShape ? new Vector3(...data.baseShape.normalVector) : new Vector3()
  const rotationSystem = useRef([
    data.parent.rotationSystemX,
    data.parent.rotationSystemY,
    data.parent.rotationSystemZ,
  ])
  const q = useMemo(()=> quaternionFromRotationVectors(...rotationSystem.current), [rotationSystem]);
  // Pole and Normal Correction:
  const defaultPoleAndNormal = useRef([
    new Vector3(1,0,0),
    new Vector3(0,1,0)
  ])
  const poleAndNormal = useRef(data.baseShape ? [
    new Vector3(...data.baseShape.poleDirVector),
    new Vector3(...data.normalVector)
  ]:[])
  const meshQuaternion = useMemo(()=> {
    return data.baseShape ? correctNormalsAndPoleDirection(
      ...defaultPoleAndNormal.current,
      ...poleAndNormal.current
    ) : new Quaternion()
    },
    [
      data.baseShape,
      poleAndNormal,
      defaultPoleAndNormal
    ]
  )
  const len = data.absoluteCoordinates ? -data.length : data.length
  const centerPoint = calculateCenterPointWithDirection(data.absoluteCoordinates ? new Vector3() :startPoint, poleDirection, len)
  const uiHelperPositions = [0,0,0]
  return (
    <group
      position={position}
    >
      <group
        quaternion={q}
      >
        <group
          position={centerPoint}
        >
          <mesh
            quaternion={meshQuaternion}
          >
            <customCakeGeometry
              args={[
                data.radius,
                data.length,
                startAngle, // Angle Start
                endAngle,  // Angle end,
                16,
                16,
                0           // Open Ended? (Should the ends Be Open?)
              ]}
            />
            <meshPhysicalMaterial
              transparent={false}
              roughness={1}
              metalness={0}
              color='#fff'
              side={0}
            />
            {/*<Arrow dir={data.NormalVector} start={[0, 0, 0]} length={2} color={"white"}/>*/}
            {/*<Arrow dir={data.PoleDirVector} start={[0, 0, 0]} length={2} color={"yellow"}/>*/}
          </mesh>
        </group>
      </group>
      {/*<Arrow dir={[1, 0, 0]} start={uiHelperPositions} length={1} color={"red"}/>*/}
      {/*<Arrow dir={[0, 1, 0]} start={uiHelperPositions} length={1} color={"lightgreen"}/>*/}
      {/*<Arrow dir={[0, 0, 1]} start={uiHelperPositions} length={1} color={"blue"}/>*/}
    </group>
  )
}

export default Cylinder


